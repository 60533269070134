<template>
	<div class="col-lg-8 col-sm-12">
		<div class="mapWrapper panel">
			<div class="panel-container">
				<div class="panel-content">
					<l-map
						class="map_container"
						:zoom="zoom"
						:center="center"
						:maxZoom="16"
						ref="Map"
						:fadeAnimation="true"
						:zoomControl="true"
						:zoomAnimation="true"
						:markerZoomAnimation="true"
						:noBlockingAnimations="true"
						@popupclose="handlePopupClose"
					>
						<l-control-layers position="topright"></l-control-layers>
						<l-control position="topright">
							<button type="button" class="btn-map btn-map-default" @click="showModal = true">
								<i class="fal fa-list-alt" :style="{ fontSize: '26px', color: '#3A3A3A' }"></i>
							</button>
						</l-control>
						<l-tile-layer
							v-for="tileProvider in tileProviders"
							:key="tileProvider.name"
							:name="tileProvider.name"
							:visible="tileProvider.visible"
							:url="tileProvider.url"
							:attribution="tileProvider.attribution"
							layer-type="base"
						/>
						<l-control-scale position="bottomright" :imperial="false" :metric="true"></l-control-scale>

						<l-marker
							v-for="marker in Mapdata"
							:lat-lng="getMarkerCoordinates(marker.geometry.coordinates)"
							:key="marker.id"
							ref="marker.id"
							:name="marker.id"
							@click="selectItem(marker.id)"
						>
							<l-icon v-if="marker.properties.mag > '5'">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									xmlns:xlink="http://www.w3.org/1999/xlink"
									:x="getMarkerSize(marker.properties.markerSize)"
									:y="getMarkerSize(marker.properties.markerSize)"
									width="40px"
									height="40px"
									:fill="marker.properties.markerColor"
									stroke="#000000"
									fill-opacity="0.7"
								>
									<polygon
										points="20.112,4.19 25.25,14.599 36.737,16.268 28.426,24.371 30.386,35.811 20.112,30.41 9.839,35.811 
	11.8,24.371 3.489,16.268 14.977,14.599 "
									/>
								</svg>
							</l-icon>
							<l-icon v-else>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									xmlns:xlink="http://www.w3.org/1999/xlink"
									width="40"
									height="40"
									:fill="marker.properties.markerColor"
									fill-opacity="0.7"
									stroke="#000000"
								>
									<circle cx="20" cy="20" :r="getMarkerSize(marker.properties.markerSize)" />
								</svg>
							</l-icon>
							<l-popup>
								<table class="table-striped balloon">
									<tbody>
										<tr>
											<td colspan="2" style="color: green; text-align: center">
												<span v-html="marker.event_type"></span>
												<span v-if="marker.properties.event_type === 'M'">{{
													lang.manual
												}}</span>
												<span v-if="marker.properties.event_type === 'A'">{{
													lang.automatic
												}}</span>
											</td>
										</tr>
										<tr>
											<td>{{ lang.time }} [GMT]</td>
											<td>
												:<span v-html="marker.time"></span>
												<span>
													{{ formatDate(marker.properties.time) }}
												</span>
											</td>
										</tr>
										<tr>
											<td>{{ lang.magnitude }}</td>
											<td>
												: <span v-html="marker.mag"></span>
												{{ marker.properties.mag }}
											</td>
										</tr>
										<tr>
											<td>{{ lang.latitude }} [°N]</td>
											<td>
												: <span v-html="marker.lat"></span>
												{{ marker.properties.lat }}
											</td>
										</tr>
										<tr>
											<td>{{ lang.longtitude }} [°E]</td>
											<td>
												: <span v-html="marker.lon"></span>
												{{ marker.properties.lon }}
											</td>
										</tr>
										<tr>
											<td>{{ lang.depth }} [km]</td>
											<td>: <span v-html="marker.depth"></span> {{ marker.properties.depth }}</td>
										</tr>
										<tr>
											<td>{{ lang.place }}</td>
											<td>
												:<span v-html="marker.location_city"></span>
												{{ marker.properties.location_city }}
											</td>
										</tr>
										<tr v-if="marker.properties.location_distance != ''">
											<td>{{ lang.location_distance }}</td>
											<td>
												:<span v-html="marker.location_distance"></span>
												{{ marker.properties.location_distance }}
											</td>
										</tr>
										<tr>
											<td>{{ lang.lastupdate }}</td>
											<td>
												:<span v-html="marker.time"></span>
												{{ getDate(marker.properties.time) }}
											</td>
										</tr>
									</tbody>
								</table>
							</l-popup>
						</l-marker>
					</l-map>
					<div v-if="showModal">
						<div class="popup">
							<div class="modal-wrapper">
								<div class="modal-container">
									<div class="modal-header d-flex justify-content-between pt-0 px-0">
										<slot
											><h3 class="pb-0 mb-0">{{ lang.legend }}</h3></slot
										>
										<slot>
											<button class="cursor-pointer" @click="showModal = false">
												<Icon
													class="far fa-times-circle text-danger"
													:style="{ fontSize: '24px' }"
												/></button
										></slot>
									</div>

									<div class="modal-body">
										<div class="magnitude">
											<div class="w-100 emri">{{ lang.magnitude }}</div>
											<div class="icon-group">
												<div class="object">
													<svg
														id="Layer_1"
														version="1.1"
														xmlns="http://www.w3.org/2000/svg"
														xmlns:xlink="http://www.w3.org/1999/xlink"
														x="0px"
														y="0px"
														width="30px"
														height="30px"
														viewBox="0 0 40 40"
														enable-background="new 0 0 40 40"
														xml:space="preserve"
													>
														<circle
															fill="none"
															stroke="#231F20"
															stroke-width="0.7"
															stroke-miterlimit="10"
															cx="20"
															cy="20"
															r="5.499"
														/>
													</svg>
													<p>0 - 2.5</p>
												</div>
												<div class="object">
													<svg
														id="Layer_1"
														version="1.1"
														xmlns="http://www.w3.org/2000/svg"
														xmlns:xlink="http://www.w3.org/1999/xlink"
														x="0px"
														y="0px"
														width="30px"
														height="30px"
														viewBox="0 0 40 40"
														enable-background="new 0 0 40 40"
														xml:space="preserve"
													>
														<circle
															fill="none"
															stroke="#231F20"
															stroke-miterlimit="10"
															cx="20"
															cy="20"
															r="7.499"
														/>
													</svg>
													<p>2.5 - 4.0</p>
												</div>
												<div class="object">
													<svg
														id="Layer_1"
														version="1.1"
														xmlns="http://www.w3.org/2000/svg"
														xmlns:xlink="http://www.w3.org/1999/xlink"
														x="0px"
														y="0px"
														width="30px"
														height="30px"
														viewBox="0 0 40 40"
														enable-background="new 0 0 40 40"
														xml:space="preserve"
													>
														<circle
															fill="none"
															stroke="#231F20"
															stroke-miterlimit="10"
															cx="20"
															cy="20"
															r="12.833"
														/>
													</svg>
													<p>4.0 - 5.0</p>
												</div>
												<div class="object">
													<svg
														id="Layer_1"
														version="1.1"
														xmlns="http://www.w3.org/2000/svg"
														xmlns:xlink="http://www.w3.org/1999/xlink"
														x="0px"
														y="0px"
														width="30px"
														height="30px"
														viewBox="0 0 40 40"
														enable-background="new 0 0 40 40"
														xml:space="preserve"
													>
														<polygon
															fill="none"
															stroke="#231F20"
															stroke-miterlimit="10"
															points="20.112,2.288 25.868,13.949 38.737,15.819 29.426,24.897 
                        31.622,37.713 20.112,31.662 8.604,37.713 10.8,24.897 1.489,15.819 14.359,13.949 "
														/>
													</svg>
													<p>5.0 +</p>
												</div>
											</div>
										</div>

										<div class="depth">
											<div class="w-100 emri">{{ lang.depth }}</div>
											<div class="icon-group">
												<div class="object">
													<svg
														id="Layer_1"
														version="1.1"
														xmlns="http://www.w3.org/2000/svg"
														xmlns:xlink="http://www.w3.org/1999/xlink"
														x="0px"
														y="0px"
														width="30px"
														height="30px"
														viewBox="0 0 40 40"
														enable-background="new 0 0 40 40"
														xml:space="preserve"
													>
														<circle
															fill="#ff0000"
															stroke="#231F20"
															stroke-miterlimit="10"
															cx="20"
															cy="20"
															r="12.499"
														/>
													</svg>
													<p>0 - 15</p>
												</div>
												<div class="object">
													<svg
														id="Layer_1"
														version="1.1"
														xmlns="http://www.w3.org/2000/svg"
														xmlns:xlink="http://www.w3.org/1999/xlink"
														x="0px"
														y="0px"
														width="30px"
														height="30px"
														viewBox="0 0 40 40"
														enable-background="new 0 0 40 40"
														xml:space="preserve"
													>
														<circle
															fill="#ff7400"
															stroke="#231F20"
															stroke-miterlimit="10"
															cx="20"
															cy="20"
															r="12.499"
														/>
													</svg>
													<p>15 - 30</p>
												</div>
												<div class="object">
													<svg
														id="Layer_1"
														version="1.1"
														xmlns="http://www.w3.org/2000/svg"
														xmlns:xlink="http://www.w3.org/1999/xlink"
														x="0px"
														y="0px"
														width="30px"
														height="30px"
														viewBox="0 0 40 40"
														enable-background="new 0 0 40 40"
														xml:space="preserve"
													>
														<circle
															fill="#ffc100"
															stroke="#231F20"
															stroke-miterlimit="10"
															cx="20"
															cy="20"
															r="12.499"
														/>
													</svg>
													<p>30 +</p>
												</div>
											</div>
										</div>
									</div>

									<div class="modal-footer">
										<div class="description mt-3">
											<p>
												{{ lang.automaticmsg }}
											</p>
											<p>
												{{ lang.manualmsg }}
											</p>
										</div>
									</div>
								</div>
							</div>
							<div class="popup-backdrop" @click="showModal = false"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import 'leaflet/dist/leaflet.css';
import {
	LMap,
	LIcon,
	LTileLayer,
	LMarker,
	LControlLayers,
	LPopup,
	LControlScale,
	LControl
} from '@vue-leaflet/vue-leaflet';
import timeAgo from '../utils/timeFormater.js';

export default {
	name: 'GeoMap',
	components: {
		LMap,
		LIcon,
		LTileLayer,
		LMarker,
		LControlLayers,
		LPopup,
		LControlScale,
		LControl
	},
	data() {
		return {
			showModal: false,
			zoom: 7,
			center: [40.79717741518766, 20.181884765625],
			size: null,
			bounds: null,
			preferCanvas: false,
			sizes: {
				small: '5',
				medium: '8',
				large: '12'
			},
			tileProviders: [
				{
					name: 'Esri Satellite',
					visible: true,
					maxZoom: 19,

					url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
					attribution:
						'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
				},
				{
					name: 'OpenStreetMap',
					visible: false,
					maxZoom: 19,

					url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
					attribution:
						'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				}
			],
			internalSelected: null
		};
	},
	computed: {
		Mapdata() {
			return this.$parent.Mapdata;
		},
		selected() {
			return this.$parent.selected;
		},
		lang() {
			return this.$parent.lang;
		},
		showLegend() {
			return this.$parent.showLegend;
		}
	},
	watch: {
		selected: {
			handler(newval) {
				if (newval) {
					this.openPopup(newval);
				} else {
					this.hidePopup();
				}
				this.internalSelected = this.selected;
			},
			immediate: true
		}
	},
	methods: {
		getMarkerSize(size) {
			return this.sizes[size];
		},
		getMarkerCoordinates(marker) {
			return [marker[1], marker[0]];
		},
		selectItem(id) {
			this.$emit('selected', { event: id, centermap: false });
		},
		openPopup() {
			let marker = this.$refs['marker.id'].find((lmarker) => lmarker.name == this.selected.event);
			marker.leafletObject.openPopup();
			if (this.selected.centermap) {
				this.$refs.Map.leafletObject.setView(marker.leafletObject.getLatLng(), 10);
			}
		},
		hidePopup() {
			if (this.internalSelected) {
				let marker = this.$refs['marker.id'].find((lmarker) => lmarker.name == this.internalSelected.event);
				marker.leafletObject.closePopup();
			}
		},
		getDate(time) {
			const date = new Date(time);
			return timeAgo.format(date);
		},
		formatDate(time) {
			const date = new Date(time);
			let options = {
				day: 'numeric',
				month: 'short',
				year: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				second: 'numeric',
				hour12: false
			};
			return new Intl.DateTimeFormat('en-GB', options).format(date);
		},
		recenterMap() {
			this.$refs.Map.leafletObject.setView(this.center, 9);
		},
		handlePopupClose() {
			this.$emit('closed');
		}
	}
};
</script>
<style lang="scss">
.leaflet-map-pane svg {
	position: relative;
	left: -50%;
	top: 0;
}
.cursor-pointer {
	cursor: pointer;
}
.map_container {
	height: 710px !important;
	width: 100% !important;
	@media screen and (max-width: 992px) {
		height: 500px !important;
	}
}
.popup {
	/*background-color: rgba(0, 0, 0, 0.5);*/
	position: absolute;
	z-index: 99999;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: 15px;
}
.popup-backdrop {
	background-color: rgba(0, 0, 0, 0.5);
	position: absolute;
	z-index: 99998;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}
.btn-map-default {
	background-color: white;
	width: 44px;
	height: 44px;
	padding: 0 0 0 0;
	margin-right: 3px;
	margin-left: 3px;
	border: 2px solid rgba(0, 0, 0, 0.329);
	border-radius: 5px;
}
.btn-map-default:hover {
	background-color: rgba(255, 255, 255);
	border: 2px solid rgba(0, 0, 0, 0.329);
}
.modal-wrapper {
	display: flex;
	width: 100%;
	justify-content: space-around;
	flex-direction: column;
	align-items: center;
	height: 100%;
	max-width: fit-content;
	margin: 0 auto;
	position: absolute;
	left: 0;
	right: 0;
	z-index: 99999;
}

.modal-container {
	max-width: 602px;
	min-height: 360px;
	margin: 0px auto;
	padding: 25px;
	background-color: #f7f4f4;
	border-radius: 2px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	transition: all 0.3s ease;
	@media screen and (max-width: 360px) {
		padding: 15px;
	}
}

.modal-header {
	margin-top: 0;
}

.btn-map {
	border: none;
	background-color: #f7f4f4;
}

.modal-body {
	margin: 10px 0;
	font-size: 16px;
}

.modal-footer {
	font-size: 12px;
	font-style: italic;
	text-align: left;
}
.modal-enter-from {
	opacity: 0;
}

.modal-leave-to {
	opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

.w-100 {
	width: 100%;
}
.icon-group {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.emri {
	display: block;
	text-align: center;
	margin-bottom: 10px;
	font-weight: bold;
}
.magnitude {
	font-size: 13px;
}
.depth {
	font-size: 13px;
}
.object {
	align-items: center;
	text-align: center;
	margin: 0 10px;
}
</style>
