<template>
	<div class="col-lg-4 col-sm-12">
		<div class="lista-json panel">
			<div class="panel-hdr">
				<h2>{{ lang.paneltitle }}</h2>
				<div class="panel-toolbar">
					<a class="cursor-pointer btn btn-circle" title="Refresh List" @click="loadData()"
						><i class="fal fa-sync"></i
					></a>
				</div>
			</div>
			<div class="panel-container">
				<div class="panel-content">
					<div class="legend">
						<label for="periodvalue" class="form-label"
							><i class="fas fa-filter"></i> {{ lang.listtitle }}
						</label>
						<select v-model="periodvalue" class="custom-select form-control">
							<option value="1">24 {{ lang.hour }}</option>
							<option value="2">48 {{ lang.hour }}</option>
							<option value="3">7 {{ lang.days }}</option>
						</select>
					</div>

					<transition name="fade" mode="in-out">
						<div class="wrapper" :class="{ show: this.isShow, hide: !this.isShow }">
							<div class="alert alert-warning no-earthquake" v-if="Mapdata.length == 0">
								<span v-if="periodvalue == 1">
									{{ lang.noearthquake24 }}
								</span>
								<span v-if="periodvalue == 2">
									{{ lang.noearthquake48 }}
								</span>
								<span v-if="periodvalue == 3">
									{{ lang.noearthquake7d }}
								</span>
							</div>

							<div
								class="item"
								:class="selected && selected.event == datas.id ? 'highlight' : ''"
								v-for="datas in Mapdata"
								:key="datas.Mapdata"
								@click="selectItem(datas.id)"
							>
								<div class="item-info mag align-items-top d-flex flex-column" v-bind="datas.mag">
									<span class="text-center">
										{{ datas.properties.mag }}
									</span>
								</div>

								<div class="item-group">
									<div class="item-info lastupdate" v-bind="datas.time">
										{{ formatDate(datas.properties.time) }}
									</div>

									<div class="item-info depth" v-bind="datas.depth">
										{{ lang.depth }} : <span class="mag-box"> {{ datas.properties.depth }}</span> KM
									</div>
									<div class="item-info depth" v-bind="datas.location_city">
										{{ lang.place }} :
										<span class="mag-box"> {{ datas.properties.location_city }}</span>
									</div>
									<div class="item-info depth" v-bind="datas.location_distance">
										<span v-if="datas.properties.location_distance != ''">
											{{ lang.location_distance }} :
											<span class="mag-box">
												{{ datas.properties.location_distance }}
											</span>
										</span>
									</div>
								</div>
								<div
									class="item-info type"
									:class="datas.properties.event_type == 'M' ? 'green' : 'red'"
									v-bind="datas.event_type"
								>
									<span style="text-transform: uppercase">[ {{ datas.properties.event_type }} ]</span>
								</div>
							</div>
						</div>
					</transition>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
//import timeAgo from '../utils/timeFormater.js';
export default {
	name: 'ListaMap',

	computed: {
		Mapdata() {
			return this.$parent.Mapdata;
		},
		selected() {
			return this.$parent.selected;
		},
		lang() {
			return this.$parent.lang;
		},
		isShow() {
			return this.$parent.isShow;
		},
		periodvalue: {
			get() {
				return this.$parent.period;
			},
			set(newval) {
				this.$parent.period = newval;
			}
		}
	},
	methods: {
		selectItem(id) {
			if (this.selected && this.selected.event === id) {
				this.$emit('selected', null);
			} else {
				this.$emit('selected', { event: id, centermap: false });
			}
		},
		formatDate(time) {
			const date = new Date(time);
			let options = {
				day: 'numeric',
				month: 'short',
				year: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				second: 'numeric',
				hour12: false
			};
			return new Intl.DateTimeFormat('en-GB', options).format(date);
		},
		toggleLegends() {
			this.$parent.showLegend = !this.$parent.showLegend;
		},
		toggleList() {
			this.$parent.isShow = !this.$parent.isShow;
		},
		loadData() {
			this.showLoader = true;
			this.$parent.loadData();
		}
	}
};
</script>
<style scoped lang="scss">
.no-earthquake {
	margin-right: 15px;
}
.alert {
	position: relative;
	padding: 1rem 1.25rem;
	margin-bottom: 2rem;
	border: 1px solid transparent;
	border-radius: 4px;
}
.alert-warning {
	color: #c18300;
	background-color: #fff8e9;
	border-color: #ffcd65;
}

.text-center {
	text-align: center;
}
.d-flex {
	display: flex !important;
}
.justify-content-between {
	justify-content: space-between !important;
}
.align-items-center {
	align-items: center !important;
}
.align-items-top {
	align-items: flex-start;
}
.flex-row {
	flex-direction: row !important;
}
.flex-column {
	flex-direction: column !important;
}
.font-weight-bold {
	font-weight: bold;
}
.wrapper {
	/*border: 1px solid #ddd;*/
	max-height: 590px;
	overflow-y: scroll;
	&::-webkit-scrollbar {
		width: 6px;
		border-radius: 4px;

		background-color: rgba(35, 128, 199, 0.3);
	}
	&::-webkit-scrollbar-track {
		/*background-color: rgba(0, 0, 0, 0.82);*/
		border-radius: 4px;
		box-shadow: inset 0 0 15px rgba(35, 128, 199, 0.3);
	}
	&::-webkit-scrollbar-thumb {
		height: 30px;
		background-color: rgba(35, 128, 199, 0.8);
		border-radius: 4px;
	}

	@media screen and (max-width: 992px) {
		height: 100%;
		max-height: 250px;
	}

	.item {
		/*background-color: #f9f9f9;*/
		border-bottom: 1px solid #ddd;
		padding: 8px 8px 8px 0;
		font-size: 14px;
		display: flex;
		align-items: center;
		cursor: pointer;
		&:last-child {
			border-bottom: 0;
		}
		.item-info {
			padding: 2px 5px;
			&.lastupdate {
				padding-bottom: 2px;
				/*color: #2380c7;*/
			}
			&.mag {
				font-size: 25px;
				min-width: 50px;
			}
			&.type {
				margin-left: 8px;
				width: 60px;
				text-align: right;
			}
		}
		.depth {
			.mag-box {
				width: 100%;
				height: 100%;
				padding: 2px;
				margin: 2px 0;
				text-align: center;
				font-weight: bold;
			}
		}
		.item-group {
			flex: 1 1 auto;
			margin-left: 15px;
		}
		&.highlight {
			background-color: yellow !important;
		}
	}
}
.green {
	color: green;
}
.red {
	color: red;
}
.form-group {
	margin-bottom: 1.5rem;
	display: block;
}
.form-label {
	font-weight: 500;
	display: inline-block;
	/*margin-bottom: 0.3rem;*/
	font-size: 0.8125rem;
}
select.custom-select {
	text-indent: 1px;
	text-overflow: '';
}
.custom-control-label::before,
.custom-file-label,
.custom-select {
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control {
	display: block;
	width: 100%;
	height: calc(1.47em + 1rem + 2px);
	padding: 0.5rem 0.875rem;
	font-size: 0.8125rem;
	font-weight: 400;
	line-height: 1.47;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #e5e5e5;
	border-radius: 4px;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-select {
	display: inline-block;
	max-width: 100px;
	width: 100%;
	height: calc(1.47em + 1rem + 2px);
	padding: 0.5rem 1.875rem 0.5rem 0.875rem;
	font-size: 0.8125rem;
	font-weight: 400;
	line-height: 1.47;
	color: #495057;
	vertical-align: middle;
	background: #fff
		url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
		no-repeat right 0.875rem center/8px 10px;
	border: 1px solid #e5e5e5;
	border-radius: 4px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
.legend {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 15px;
	border-bottom: 3px solid #2380c7;
	padding-bottom: 15px;
}
.cursor-pointer {
	cursor: pointer;
}
/************** animation ********************/
</style>
