<template>
	<MainContainer />
</template>

<script>
import MainContainer from './components/MainContainer.vue';

export default {
	name: 'App',
	components: {
		MainContainer
	}
};
</script>

<style lang="scss" scoped src="./assets/include_css/App.scss"></style>
