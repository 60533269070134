<template>
	<!-- Kjo behet koment vetem per build -->
	<header class="jv-header">
		<div class="hader-top py-4">
			<div class="container">
				<div class="d-flex justify-content-between align-items-center">
					<div class="logo-area">
						<img alt="logo" src="../../public/demoassets/graphics/logo.png" />
					</div>
					<div class="topRowArea ml-auto">
						<a
							class="px-1 text-md-left text-center cursor-pointer"
							:class="{ active: isActive }"
							@click="lngcode = 'sq'"
							>Shqip</a
						>
						<span class="mx-3">|</span>
						<a
							class="px-1 text-md-left text-center cursor-pointer text-underline"
							:class="{ active: isActive }"
							@click="lngcode = 'en'"
							>Anglisht</a
						>
					</div>
				</div>
			</div>
		</div>
		<nav class="jv-navbar jv-navbar-top jv-navbar-white-text-on-top">
			<div class="container">
				<div class="jv-nav-table">
					<ul id="MainNav" class="jv-nav collapse pl-0">
						<li class="nav-item nav-item-styled dropdown22">
							<a href="https://geo.edu.al" class="nav-link"
								><i class="far fa-chevron-circle-left"></i> {{ lang.home }}</a
							>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	</header>
	<!-- Hiqet clasa container me poshte vetem per pjesen e build -->
	<div class="container main">
		<div class="d-flex justify-content-start align-items-center">
			<!-- Behet koment h2 vetem per build -->
			<h2 style="padding-top: 30px; padding-bottom: 0px; margin-bottom: 15px">{{ lang.headline }}</h2>
			<!-- <div class="ml-auto pt-3">
			<a class="mr-3 cursor-pointer" @click="lngcode = 'sq'">SQ</a>
			<a class="mr-3 cursor-pointer" @click="lngcode = 'en'">EN</a>
			<a class="mr-3 cursor-pointer" @click="toggleList()"><i class="fal fa-list"></i></a>
		</div> -->
		</div>
		<loaderlistajson v-if="showLoader" />
		<div class="row equal-height-blocks mb-5">
			<GeoMap @selected="selectItem" @closed="selected = null" />
			<ListaMap @selected="selectItem" />
		</div>
	</div>
	<!-- Behet koment vetem per build -->
	<!-- <footer> -->
	<!-- <div class="copyrightArea bg-grey py-4">
			<div class="container">
				<div class="d-flex justify-content-between">
					<div id="FooterContactArea">
						<div class="si_wraper">
							<p class="mb-0">
								Copyright<strong> ©2023</strong> Institute of GeoSciences. All rights reserved
							</p>
						</div>
					</div>
					<p class="mb-0">
						Supported by
						<a href="https://www.arkit.ch/" target="_blank">ARK IT</a>
					</p>
				</div>
			</div>
		</div> -->
	<!-- <div class="copyrightArea text-white py-4" style="background-color: #00589c !important">
			<div class="container-xl">
				<div class="row">
					<div class="address col-md-9 col-12">
						<div class="si_wraper">
							<h4 class="text-white">Institute of GeoSciences.</h4>
							<div>
								<strong>Address: </strong>Street: Don Bosko, No.60, Tirana<br />
								<strong>Phone: </strong><a class="text-white" href="tel:042 250 601">042 250 601</a>,
								<strong>Fax: </strong><a class="text-white" href="tel:042 259 540">042 259 540</a><br />
								<strong>E-mail: </strong
								><a class="text-white" href="mailto:info@geo.edu.al">info@geo.edu.al</a>
							</div>
							<div class="py-3">
								Copyright<strong> ©</strong><span class="font-weight-bold"> 2023 </span> Institute of
								GeoSciences. All rights reserved
							</div>
						</div>
					</div>
					<div class="col-md-3 col-12">
						<div class="d-flex flex-column">
							<div class="Copyright">
								<h4 class="text-white">Creative Commons License</h4>
								<div class="d-flex align-items-center mb-3">
									<a
										class="mr-3"
										href="http://creativecommons.org/licenses/by/4.0/"
										rel="license"
										target="_blank"
										><img
											alt="Creative Commons License"
											src="https://i.creativecommons.org/l/by/4.0/88x31.png"
											style="border-width: 0"
									/></a>
									<div style="font-size: 12px">
										This work is licensed under a
										<a
											class="text-white"
											href="http://creativecommons.org/licenses/by/4.0/"
											rel="license"
											target="_blank"
											>Creative Commons Attribution 4.0 International License</a
										>.
									</div>
								</div>
							</div>
							<div class="d-flex justify-content-between mt-1">
								<p class="mb-0 mr-2">
									Supported by
									<a
										class="text-white"
										style="text-decoration: underline"
										href="https://www.arkit.ch/"
										target="_blank"
										>ARK IT</a
									>
								</p>
								<div class="d-inline-block">
									<ul class="inline list-unstyled">
										<li class="d-inline-block mr-1">
											<a
												class="text-white"
												target="_blank"
												href="https://www.facebook.com/InstitutiGjeoshkencave"
												><i class="fa fa-facebook"></i
											></a>
										</li>
										<li class="d-inline-block mr-1">
											<a
												class="text-white"
												target="_blank"
												href="https://www.instagram.com/instituti_gjeoshkencave/"
												><i class="fa fa-instagram"></i
											></a>
										</li>
										<li class="d-inline-block mr-1">
											<a
												class="text-white"
												target="_blank"
												href="https://twitter.com/IGEO_ALBANIA"
												><i class="fa fa-twitter"></i
											></a>
										</li>
										<li class="d-inline-block">
											<a
												class="text-white"
												target="_blank"
												href="https://www.linkedin.com/in/instituti-gjeoshkencave-264260251/"
												><i class="fa fa-linkedin"></i
											></a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div> -->
	<!-- </footer> -->
</template>

<script>
import loaderlistajson from './loader-listajson.vue';
import GeoMap from './Map.vue';
import ListaMap from './listajson.vue';
import axios from 'axios';
import languages from '../assets/language.js';
const GetURL =
	window.earthquakeconfig && window.earthquakeconfig.getdataurl
		? window.earthquakeconfig.getdataurl
		: process.env.VUE_APP_GET_URL;
export default {
	name: 'MainContainer',
	components: {
		loaderlistajson,
		GeoMap,
		ListaMap
	},
	data() {
		return {
			showLoader: false,
			Mapdata: [],
			selected: null,
			lngcode:
				window.earthquakeconfig && window.earthquakeconfig.lngcode ? window.earthquakeconfig.lngcode : 'sq',
			languages: languages,
			isShow: true,
			isActive: false,
			showLegend: true,
			getJSONURL: GetURL,
			period: window.earthquakeconfig && window.earthquakeconfig.period ? window.earthquakeconfig.period : '1'
		};
	},
	watch: {
		period() {
			this.loadData();
		}
	},
	created() {
		this.loadData();
		this.lngcode =
			window.earthquakeconfig && window.earthquakeconfig.lngcode ? window.earthquakeconfig.lngcode : 'sq';
		//console.log(window.earthquakeconfig);
	},
	computed: {
		lang() {
			return this.languages[this.lngcode];
		}
	},
	methods: {
		selectItem(item) {
			this.selected = item;
		},
		closePopup() {},
		toggleLegends() {
			this.showLegend = !this.showLegend;
			//console.log(this.showLegend);
		},
		toggleList() {
			this.isShow = !this.isShow;
		},
		loadData() {
			let axiosConfig = {
				headers: {
					'Content-Type': 'application/json'
				},
				params: {
					period: this.period
				}
			};
			this.showLoader = true;
			/*axios.defaults.timeout = 1800;*/
			axios.get(this.getJSONURL, axiosConfig).then((res) => {
				this.showLoader = false;
				this.Mapdata = res.data.features;
			});
			setTimeout(this.loadData, 200000);
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.cursor-pointer {
	cursor: pointer;
}
</style>
