<template>
	<div class="loader-wrapper">
		<div class="inner-wrapper">
			<div id="circularG">
				<div id="circularG_1" class="circularG"></div>
				<div id="circularG_2" class="circularG"></div>
				<div id="circularG_3" class="circularG"></div>
				<div id="circularG_4" class="circularG"></div>
				<div id="circularG_5" class="circularG"></div>
				<div id="circularG_6" class="circularG"></div>
				<div id="circularG_7" class="circularG"></div>
				<div id="circularG_8" class="circularG"></div>
			</div>
			<div class="msg">{{ lang.loadingmsg }}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'loader-listajson',
	computed: {
		lang() {
			return this.$parent.lang;
		}
	}
};
</script>

<style scoped>
.loader-wrapper {
	width: 100%;
	height: 100%;
	z-index: 9999;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.4);
}
.loader-wrapper .inner-wrapper {
	position: absolute;
	top: 45%;
	max-width: 300px;
	height: auto;
	width: 100%;
	left: 0;
	right: 0;
	margin: 0 auto;
}
#circularG {
	position: relative;
	width: 58px;
	height: 58px;
	margin: auto;
	top: 40%;
	left: 0;
}
.msg {
	position: relative;
	width: 100%;
	text-align: center;
	color: #fff;
	margin-top: 30px;
}

.circularG {
	position: absolute;
	background-color: rgb(255, 255, 255);
	width: 14px;
	height: 14px;
	border-radius: 9px;
	-o-border-radius: 9px;
	-ms-border-radius: 9px;
	-webkit-border-radius: 9px;
	-moz-border-radius: 9px;
	animation-name: bounce_circularG;
	-o-animation-name: bounce_circularG;
	-ms-animation-name: bounce_circularG;
	-webkit-animation-name: bounce_circularG;
	-moz-animation-name: bounce_circularG;
	animation-duration: 0.628s;
	-o-animation-duration: 0.628s;
	-ms-animation-duration: 0.628s;
	-webkit-animation-duration: 0.628s;
	-moz-animation-duration: 0.628s;
	animation-iteration-count: infinite;
	-o-animation-iteration-count: infinite;
	-ms-animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
	-moz-animation-iteration-count: infinite;
	animation-direction: normal;
	-o-animation-direction: normal;
	-ms-animation-direction: normal;
	-webkit-animation-direction: normal;
	-moz-animation-direction: normal;
}

#circularG_1 {
	left: 0;
	top: 23px;
	animation-delay: 0.238s;
	-o-animation-delay: 0.238s;
	-ms-animation-delay: 0.238s;
	-webkit-animation-delay: 0.238s;
	-moz-animation-delay: 0.238s;
}

#circularG_2 {
	left: 6px;
	top: 6px;
	animation-delay: 0.314s;
	-o-animation-delay: 0.314s;
	-ms-animation-delay: 0.314s;
	-webkit-animation-delay: 0.314s;
	-moz-animation-delay: 0.314s;
}

#circularG_3 {
	top: 0;
	left: 23px;
	animation-delay: 0.39s;
	-o-animation-delay: 0.39s;
	-ms-animation-delay: 0.39s;
	-webkit-animation-delay: 0.39s;
	-moz-animation-delay: 0.39s;
}

#circularG_4 {
	right: 6px;
	top: 6px;
	animation-delay: 0.466s;
	-o-animation-delay: 0.466s;
	-ms-animation-delay: 0.466s;
	-webkit-animation-delay: 0.466s;
	-moz-animation-delay: 0.466s;
}

#circularG_5 {
	right: 0;
	top: 23px;
	animation-delay: 0.542s;
	-o-animation-delay: 0.542s;
	-ms-animation-delay: 0.542s;
	-webkit-animation-delay: 0.542s;
	-moz-animation-delay: 0.542s;
}

#circularG_6 {
	right: 6px;
	bottom: 6px;
	animation-delay: 0.628s;
	-o-animation-delay: 0.628s;
	-ms-animation-delay: 0.628s;
	-webkit-animation-delay: 0.628s;
	-moz-animation-delay: 0.628s;
}

#circularG_7 {
	left: 23px;
	bottom: 0;
	animation-delay: 0.704s;
	-o-animation-delay: 0.704s;
	-ms-animation-delay: 0.704s;
	-webkit-animation-delay: 0.704s;
	-moz-animation-delay: 0.704s;
}

#circularG_8 {
	left: 6px;
	bottom: 6px;
	animation-delay: 0.78s;
	-o-animation-delay: 0.78s;
	-ms-animation-delay: 0.78s;
	-webkit-animation-delay: 0.78s;
	-moz-animation-delay: 0.78s;
}

@keyframes bounce_circularG {
	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.3);
	}
}

@-o-keyframes bounce_circularG {
	0% {
		-o-transform: scale(1);
	}

	100% {
		-o-transform: scale(0.3);
	}
}

@-ms-keyframes bounce_circularG {
	0% {
		-ms-transform: scale(1);
	}

	100% {
		-ms-transform: scale(0.3);
	}
}

@-webkit-keyframes bounce_circularG {
	0% {
		-webkit-transform: scale(1);
	}

	100% {
		-webkit-transform: scale(0.3);
	}
}

@-moz-keyframes bounce_circularG {
	0% {
		-moz-transform: scale(1);
	}

	100% {
		-moz-transform: scale(0.3);
	}
}
</style>
