export default {
	en: {
		paneltitle: 'Latest earthquakes',
		listtitle: 'Filter latest earthquakes during',
		legend: 'Legend',
		depth: 'Depth',
		hour: 'Hours',
		days: 'Days',
		magnitude: 'Magnitude',
		time: 'Time',
		latitude: 'Latitude',
		longtitude: 'Longtitude',
		lastupdate: 'Lastupdate',
		origintime: 'Date & Time UTC',
		place: 'Place',
		solution: 'Solution A/M',
		location_name: 'Location',
		magAuthor: 'Station Code',
		manual: 'Manual Solution',
		manualmsg: '[ M ] - Processed and refined data by seismology specialists at the IGEO Institute.',
		automatic: 'Automatic Solution',
		automaticmsg: '[ A ] - Raw data arriving from the seismic activity monitoring sensors.',
		loadingmsg: 'Please wait while the data is loaded!',
		home: 'Back to Homepage',
		headline: 'Earthquakes information',
		location_distance: 'Distance',
		noearthquake24: 'There are no earthquakes during last 24 hours.',
		noearthquake48: 'There are no earthquakes during last 48 hours.',
		noearthquake7d: 'There are no earthquakes during last 7 days.'
	},
	sq: {
		paneltitle: 'Lista e tërmeteve',
		listtitle: 'Filtro listën e tërmeteve gjatë',
		legend: 'Legjenda',
		depth: 'Thellësia',
		hour: 'Orë',
		days: 'Ditë',
		magnitude: 'Magnituda',
		time: 'Ora',
		latitude: 'Latituda',
		longtitude: 'Longtituda',
		origintime: 'Data dhe ora',
		lastupdate: 'Përditësimi i fundit',
		place: 'Vendndodhja',
		solution: 'Zgjidhja A/M',
		location_name: 'Vendodhja',
		magAuthor: 'Kodi i Stacionit',
		manualmsg:
			'[ M ] - Të dhëna të përpunuar dhe të saktësuara nga specialistët e sizmologjisë në Institutin IGEO.',
		manual: 'Zgjidhje Manuale',
		automatic: 'Zgjidhje Automatike',
		automaticmsg:
			'[ A ] - Të dhëna të papërpunuara të cilat mbërrijnë nga sensorët e rrjetit të monitorimit të aktivitetit sizmik.',
		loadingmsg: 'Ju lutem prisni derisa të ngarkohen të dhënat!',
		home: 'Kthehu tek Faqjakryesore',
		headline: 'Rrjeti Kombëtar i Monitorimit Sizmik',
		location_distance: 'Distanca',
		noearthquake24: 'Nuk ka ndodhur asnjë tërmet gjatë 24 orëve të fundit.',
		noearthquake48: 'Nuk ka ndodhur asnjë tërmet gjatë 48 orëve të fundit.',
		noearthquake7d: 'Nuk ka ndodhur asnjë tërmet gjatë 7 ditëve të fundit.'
	}
};
